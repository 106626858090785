@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}
.AppFormLeft {
 
  max-width: 400px;
  padding: 30px 20px;
  border-radius: 15px;
  width: 100%;
}
.site-logo {
  background-image: url('../src/assets/images/calendar_logo.png');
  background-size: contain;
  max-width: 200px;
  height: 60px;
  width: 100%;
  background-repeat: no-repeat;
}
.cus_bg{
  border: none!important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1)!important;
  font-size: 15px!important;
}
.AppForm .AppFormLeft h1{
  font-size: 35px;
}
.AppForm .AppFormLeft input:focus{
  border-color: #ced4da;
  box-shadow: none;
  outline: none;
}
.AppForm .AppFormLeft input::placeholder{
 font-size: 15px;
}
.AppForm .AppFormLeft i{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  color: #a8b0b0;


}
.AppForm .AppFormLeft a{
  color: #3a3e42 ;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
}
.inner-form input[type="checkbox"] {
  font-size: 15px;
}
.inner-form .form-check label.form-check-label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 22px;
  color: #3a3e42;
}

.AppForm .AppFormLeft .btn-success {
    background: linear-gradient(45deg,#e87932,#f32419c9);
    border-radius: 30px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;

}
.AppForm .AppFormLeft p span {
  color: #f74c26f2;
  cursor: pointer;

}
.min-h100{
  min-height: 100%;
}

.AppForm .AppFormRight p{
  z-index: 1;
} 
.title-heading{
  font-size: 20px;
    font-weight: 600;
    text-transform: none!important;
    margin-bottom: 35px;
}
.title-heading p{
  font-size: 18px;
    font-weight: 600;
    padding-top: 5px;
    text-transform: none!important;
}
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="text"]::placeholder,
input[type="tel"]::placeholder {
  font-size: 17px!important;
  color: #a8b0b0!important;
}

/*signup page css */
.custom-checkbox input[type="checkbox"] {
  position: relative;
  width: 40px;
  height: 20px;
  -webkit-appearance: none;
  appearance: none;
  background: rgb(221, 221, 221);
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
  
}

.custom-checkbox input[type="checkbox"]::before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 1px;
  left: 2px;
  transition: 0.5s;
  border: 1px solid rgb(221, 221, 221);
}

.custom-checkbox input[type="checkbox"]:checked::before {
  transform: translateX(100%);
  background: #fff;
}

.custom-checkbox input[type="checkbox"]:checked {
  background: linear-gradient(45deg,#e87932,#f32419c9);
}
.custom-checkbox span {
  font-size: 14px;
  max-width: 80%;

}
.custom-checkbox span a {
  color: #f74c26f2!important;
  font-size: 14px!important;
}
.signup-form .accordion .accordion-item {
  border: none!important;
  background: transparent;
}
.signup-form  button.accordion-button.collapsed:focus {
  box-shadow: none;
}
.signup-form .accordion-button:not(.collapsed) {
  background: transparent;
  box-shadow: none;
  font-size: 12px;
  font-weight: 600;
  color: #000;
}
.signup-form .accordion-button::after {
  margin-left: 10px;
}
.signup-form .accordion-button{
  font-size: 12px;
  font-weight: 600;
  color: #000;
}
.signup-form .accordion-button,
.signup-form .accordion-body{
  padding: 0!important;
}
.signup-form .accordion-body{
  padding-top: 15px!important;
}
.video-assessment-screen{
  min-height: 100%;
  height:100%;
}
.mh100{
  min-height:100%;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
  
.loading-spinner {
  border: 7px solid #f3f3f3;
  border-top: 7px solid #f7ae65;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1.5s linear infinite;
}
  
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.content-container {
  transition: transform 1s;
}

.slide-in {
  transform: translateY(0%);
}

.slide-out {
  transform: translateY(-100%);
}
button.button__Button-hkteey-0 {
  border: 1px solid #fff!important;
  border-radius: 50%!important;
  width: 100%!important;
  max-width: 100px!important;
  height: 100px!important;
  background-color: #f81!important;
  color: #fff!important;
  font-weight: 600!important;
  font-size: 20px!important;
}
.success_loader svg {
  height: 300px!important;
}
.success_loader .intro-text {
  padding-top: 0;
}
.success_loader .intro p {
  font-weight: 400;
}
.moving_text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 250px!important;
  transform: translate(-30%, -50%);
}
.moving_text p {
  text-align: center;
  background-color: #000;
  color: #fff;
  width: 250px;
  border: 1px solid transparent;
  border-radius: 20px;
  font-size: 20px;
}
.wrapper {
  height: 50vh;
}

.videos {
  height: 100%;
  width: 100%;
}
@media (max-width:767px){
  .video-assessment-screen {
    
    height: auto!important;
}
.fff { background-image: none;}
.typing-demo{    top: 19%;max-width: 69%;

  left: 2px;max-height: 56px;
}  
.video-main {

  font-size: 22px;
}
.waves {

  width: 100px;
  height: 100px;
  right: -29px;
  bottom: -27px;
 
}
.typing-demo p {
  margin-bottom: 0px;
  word-break: break-all;
  font-size: 13px;
}
.wrapper i.fa.fa-play {
  position: relative;
  top: -12px;
  left: -11px;
}
.wrapper {
  height: 23vh;
  top: 12%;
  left: auto;
  transform: translate(0,-12%);
  right: -9px;
}
.video-assessment-screen video, .video-assessment-screen .videoContainer, .fff {
  min-height: 63vh!important;
  max-height: 72vh!important;
}
.Powered_logo{    top: 16px;
  left: -34px;}
  .orion_logo img {
    width: 52px;
}
.left_sec {
  min-height: 20%;
  height: 20vh;
}
}
.landing_page .orion-logo {
  margin-top: -60px;
  margin-bottom: -10!important;
}